/* Sections */

.pitch,
.smart-nft,
.many-features,
.loot-box,
.marketplace,
.team,
.roadmap,
.news {
  padding-top: 135px;
  padding-bottom: 135px;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

p {
  max-width: 700px !important;
}

.home .header .right-col{
  margin-top: 70px;
}

.meet-button{
  margin-left: 25px;
  text-decoration: none;
  color: #768097;
  font-size: 19px;
  font-weight: 600;
}

.meet-button:hover{
  color: #768097;
}



/* Buttons */

.btn.btn-primary {
  width: 200px;
  height: 50px;

  background: #00d1ff;
  border-radius: 5px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  border: none;
  background: #00d1ff;

  transition: all 0.2s ease;
  line-height: 38px;
}

.btn.btn-primary:hover {
  background: #00d1ff;
  box-shadow: none;
  filter: brightness(0.85);
}

.btn.btn-primary:focus {
  background: #00d1ff;
  box-shadow: none;
  filter: brightness(0.85);
}

.btn.btn-primary:active {
  background: #00d1ff;
  box-shadow: none !important;
  filter: brightness(0.6);
}

/* socials-icons */

.navbar {
  background-color: white !important;
}

.navbar .navbar-brand {
  margin-left: 30px;
  margin-right: 44px;
}

.navbar .nav-link {
  color: rgba(0, 9, 55, 0.5) !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  margin-right: 20px;
}

.navbar .dropdown-item {
  color: rgba(0, 9, 55, 0.5) !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
}

.navbar .dropdown-item:active {
  background-color: rgba(0, 9, 55, 0.5) !important;
}

.navbar .nav-marketplace-button {
  width: 150px;
  height: 44px;
  margin-right: 24px;
  line-height: 32px;
}

.navbar .socials-icons {
  color: rgba(0, 9, 55, 0.32);
  margin-right: 25px;
}

/* Home */

/* Header */

.home .header .title {
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 108px;
  text-transform: uppercase;
  color: #000937;
}

.home .header .left-col {
  text-align: left;
  padding-left: 50px;
  margin-top: 80px;
}

.home .header .right-col {
  height: 930px;
  /* optomized*/
  overflow-x: hidden;
}


.home .header .heading-illustration {
  position: absolute;
  top: 130px;
  right: 140px;
  width: 532px;
  height: 585.2px;
  z-index: -1;
}


.home .header .tagline {
  font-style: normal;
  font-weight: 350;
  font-size: 21px;
  line-height: 30px;
  color: #000937;
  margin-top: 24px;
  margin-bottom: 64px;
}

.home .header .buy-alu-btn {
  background: -webkit-linear-gradient(
    left,
    rgba(0, 209, 255, 0.16) 0%,
    rgba(0, 209, 255, 0) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 209, 255, 0.16) 0%,
    rgba(0, 209, 255, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 209, 255, 0.16) 0%,
    rgba(0, 209, 255, 0) 100%
  );

  border: none;
  font-weight: 500;
  color: #000937;
  margin-left: 25px !important;
  width: 300px!important
}

.home .header .buy-alu-btn-ellipse {
  background: -webkit-linear-gradient(292deg, #00d1ff 18.54, #ed1eff 64.8);
  background: -o-linear-gradient(292deg, #00d1ff 18.54, #ed1eff 64.8);
  background: linear-gradient(22deg, #00d1ff 18.54, #ed1eff 64.8);
}

.vector-circle-image,
.vector-circle-image-2 {
  width: 917px;
  height: 917px;
  position: absolute;
  padding: 0;
  pointer-events: none;
  max-width: 100vw;
  overflow: hidden;
  z-index: -1;
}

.vector-circle-image {
  top: -150px;
  left: -200px;
}

.vector-circle-image-2 {
  right: 0px;
}

.color-circle-image-1,
.color-circle-image-2,
.color-circle-image-3,
.color-circle-image-4,
.color-circle-image-5,
.color-circle-image-6,
.color-circle-image-7,
.color-circle-blog-1,
.color-circle-blog-2,
.color-circle-blog-3,
.color-circle-404-1,
.color-circle-404-2,
.color-circle-404-3 {
  position: absolute;
  padding: 0;
  pointer-events: none;
  z-index: -1;
  max-width: 100% !important;
  overflow: hidden!important;
  overflow-x: hidden!important;
}

.color-circle-image-7 {
  right: 0;
}

.color-circle-404-2 {
  right: 0;
}

.color-circle-404-3 {
  bottom: 0;
}

.color-circle-image-1 {
  left: -200px;
  width: 839px;
  height: 839px;
}

.color-circle-image-3 {
  right: 0;
}

.color-circle-image-4 {
  right: 0;
}

.color-circle-image-5 {
  left: 0;
}

.color-circle-image-6 {
  left: 0;
}

.color-circle-blog-3 {
  left: 0;
}

.home .header .alu-logo-image {
  width: 155px;
  height: 155px;
  position: absolute;
  padding: 0;
  right: 520px;
}

.home .header .buy-alu-semi-circle {
  margin-left: -120px;
  margin-top: -6px;
}

.home .header .explore-text {
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height */

  color: rgba(0, 9, 55, 0.5);
  margin-top: 82px;
}

.home .header .explore-image {
  max-width: 90px;
  max-height: 50px;
}

.home .header .explore-image-col {
  margin-right: 30px;
}

/* Pitch */

.pitch .subheading-lg {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  /* identical to box height */

  text-align: center;

  color: #000937;
  margin-bottom: 20px;
}

.pitch p {
  max-width: 1080px;
  margin: auto;
}

.pitch .marketcap-and-holders {
  margin-top: 102px;
}

.pitch .marketcap-and-holders .holders,
.pitch .marketcap-and-holders .marketcap {
  margin-bottom: 28px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 187% */

  text-align: center;
  text-transform: uppercase;
}

.pitch .marketcap-and-holders .holders {
  color: #00d1ff;
}

.pitch .marketcap-and-holders .marketcap {
  color: #ed1eff;
}

.pitch .marketcap-and-holders .value {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 76px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  color: #000000;
}

/* Smart NFT  */

.feature-subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;

  color: #000000;
  margin-bottom: 20px;
}

.feature-desc {
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 30px;
  /* or 187% */

  color: rgba(0, 9, 55, 0.5);
  margin-bottom: 56px;
}

.smart-nft-img {
  width: 412px;
  height: 471px;
}

/* Many Features */

.many-features .subheading-md {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase;

  color: #000937;
  margin-bottom: 115px;
}

.many-features .mini-feature-icon {
  width: 75px;
  height: 75px;
  margin-bottom: 42px;
  color: #00d1ff !important;
}

.many-features .mini-feature-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 37px;
  text-align: center;

  color: #000937;
}

.many-features .mini-feature-text {
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */

  text-align: center;

  color: rgba(0, 9, 55, 0.5);
  max-width: 350px;
}

.many-features .mini-features-top-row {
  margin-bottom: 95px;
}

/* Team */

.team .face-image {
  max-height: 150px;
  margin-bottom: 30px;
}

.team .team-name {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 37px;
  text-align: center;

  color: #000937;
}

.team-title {
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */

  text-align: center;
  text-transform: uppercase !important;

  color: rgba(0, 9, 55, 0.5);
  margin-bottom: 36px;
}

.team-icons {
  color: rgba(0, 9, 55, 0.32);
  margin-left: 14px;
  margin-right: 14px;
}

.team .top-row {
  margin-bottom: 60px;
}

.team .mesh-gradient {
  height: 378px;
  position: absolute;
  z-index: -1;
  overflow: hidden;
}

.team .team-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 76px;
  text-transform: uppercase;

  color: #000937;
  position: relative;
  top: 96px;
  left: 60px;
  display: inline-block;
}

.team .alu-logo-image-div {
  position: relative;
  z-index: -1;
}

.team .alu-logo-image {
  width: 93px;
  height: 93px;
  position: absolute;
  left: 120px;
  transform: rotate(-45deg);
}

/* Roadmap */

.roadmap {
  /* optomized */
  background-image: url("https://i.imgur.com/oFfc6xh.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.roadmap .roadmap-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 76px;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;
  margin-bottom: 66px;
}

.roadmap .roadmap-section {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 24px;
  max-width: 968px;
  min-height: 182px;
  margin-bottom: 22px;
}

.roadmap .row,
.roadmap .col {
  height: 100%;
}

.roadmap .roadmap-image-div {
  position: relative;
  height: 182px;
  width: 100%;
}

.roadmap .roadmap-image {
  width: 112px;
  height: 112px;
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.roadmap .roadmap-section .title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000937;
  margin-top: 22px;
}

.roadmap .roadmap-section .list {
  margin-top: 12px;
}

.roadmap .roadmap-section .list-right {
  margin-top: 72px;
}

.roadmap .roadmap-section .point {
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */

  color: rgba(0, 9, 55, 0.72);
}

.roadmap .roadmap-rect {
  position: relative;
  bottom: 30px;
}

/* News */

.news .news-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  text-transform: uppercase;

  color: #000937;
  margin-bottom: 80px;
}

.news .article-lg {
  max-width: 100%;
  height: 500px;
  text-decoration: none !important;
}

.news .article-lg .title {
  text-decoration: none !important;
}

.news .article-image {
  object-fit: contain;
}

.news .article-lg img {
  max-width: 100%;
  height: 368px;
}

.news .article-lg .info {
  background: #ffffff;
  box-shadow: 0px 24px 56px rgba(0, 0, 0, 0.08);
  width: 612px;
  height: 132px;
  padding-left: 30px;
  padding-top: 12px;
}

.news .article-lg .title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 44px;
  text-transform: uppercase;

  color: #000937;
}

.news .article-lg .body {
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 22px;

  color: rgba(0, 9, 55, 0.5);
}


.news .article-sm img {
  width: 298px;
  height: 298px;
}

/* .text-gradient{
  background: -webkit-linear-gradient(left, #00d1ff, #ed1eff);
  background: -o-linear-gradient(left, #00d1ff, #ed1eff);
  background: linear-gradient(to right, #00d1ff, #ed1eff);
  font-weight: 700;
  font-size: 4rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.1rem;
} */

.news .title-sm {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;

  color: #000937;
  width: 274px;
  display: inline-block;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 0px;
}

.news .body-sm {
  font-style: normal;
  font-weight: 350;
  font-size: 12px;
  line-height: 19px;

  color: rgba(0, 9, 55, 0.5);

  width: 274px;
  display: inline-block;
  text-align: left;
  margin-top: 8px;
}

.news .blog-button,
.news .twitter-button {
  width: 612px;
  height: 80px;

  border-radius: 6px;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height */

  letter-spacing: 0.12em;
  text-transform: uppercase;

  color: #ffffff;
  display: table-cell;
  vertical-align: middle;
  padding-left: 28px;
  position: relative;
  text-decoration: none;
}

a:link {
  text-decoration: none !important;
}

.news .blog-button {
  background: #ed1eff;
}

.news .twitter-button {
  background: #00d1ff;
}

.news .news-buttons {
  margin-top: 64px;
}

.news .blog-button-icon {
  position: absolute;
  right: 0;
  top: 0;
}

/* Partners */

.partners .partners-subheading {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;
  margin-bottom: 50px;
}

.partners .partner-logo {
  max-width: 120px;
  max-height: 50px;
}

.partners .partner-col {
  margin-bottom: 60px;
}

.partners-row-upper,
.partners-row-lower {
  padding-left: 137px;
  padding-right: 137px;
}

.partners-row-upper {
  margin-bottom: 75px;
}

.partners-row-upper {
  margin-bottom: 0 !important;
}

.partners {
  /* padding-top: 60px !important; */
}

/* Footer */

.footer {
  /* Opotmized */
  background-image: url("https://i.imgur.com/dsTOf7w.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 32px;
}

.footer .footer-frosted {
  /* margin-top: 50px; */
  padding-top: 40px;
  padding-bottom: 40px;
  background: rgba(255, 255, 255, 0.16);
  box-shadow: 8px 12px 40px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(100px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 0px 6px 6px 0px;
  width: calc(100vw - 40px);
}

.footer .footer-subheading {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height, or 114% */

  color: #000937;
  margin-bottom: 12px;
}

.footer .alu-footer-logo {
  height: 70px;
  margin-bottom: 30px;
}

.footer .footer-desc {
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 22px;

  color: #000937;
}

.footer .footer-links-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 200% */

  color: #000937;
}

.footer .footer-link {
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 25px;
  /* identical to box height */

  margin-bottom: 5px;

  color: rgba(0, 9, 55, 0.64);
  text-decoration: none;
  display: block;
}

.footer .links-row {
  margin-top: 120px;
}

.footer .cc-text {
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height */

  color: #13788e;
  margin-bottom: 0;
}

.footer .bottom-footer-link {
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height */

  color: black;
  text-decoration: none;
}

.bottom-footer-row {
  padding-bottom: 24px;
  padding-top: 24px;
}

.blog {
  margin-top: 80px;
}

.blog.news {
  margin-top: 0;
}

.subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  color: #000937;
  margin-top: 16px;
}

.subtitle-mailing-list {
  font-weight: 400;
  font-size: 18px;
  line-height: 33px;
  /* identical to box height */

  color: #6c757e;
}

.blog .title {
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 100px;
  /* identical to box height, or 125% */

  color: #000937;
}

.title-waiting-list {
  color: #000937;
  font-weight: 700;
}

.blog .MuiToggleButtonGroup-root {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 24px;
  margin-top: 64px;
  margin-left: auto;
  margin-right: auto;

  margin-bottom: 56px;
}

.blog .MuiButtonBase-root.MuiToggleButton-root,
.blog .MuiButtonBase-root.MuiToggleButton-root:active,
.blog .MuiButtonBase-root.MuiToggleButton-root:focus {
  background: none;
  border: none;
}

.blog .MuiButtonBase-root.MuiToggleButton-root[aria-pressed="true"] {
  background: #00d1ff;
  color: white;
  border-radius: 45px;
  border: 5px solid white;
  box-sizing: content-box;
  padding: 6px;
}

.blog .blog-mesh {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: -1;
  overflow: hidden;
}

.blog .vector-circle-image-2 {
  right: 0 !important;
  top: -30px !important;
  z-index: -1;
  overflow: hidden;
}

.article .img {
  width: 402.67px;
  height: 240px;
  object-fit: cover;
  overflow: hidden;
}

.blog.news .article .img {
  border-radius: 5px;
}

.blog-view .article .img {
  border-radius: 9px;
}

.news .article .img {
  width: 350px;
  height: 175px;
}

.article .article-body {
  padding-top: 18px;
  padding-bottom: 18px;
  text-align: left !important;
  max-width: 350px;
}

.article .date,
.roadmap .date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: rgba(0, 9, 55, 0.5);
}

.article .title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  color: #000937;
}

.article .body {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: rgba(0, 9, 55, 0.5);
}

.article {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.contact .row {
  min-height: calc(100vh - 82px);
}

.contact .left-col {
  background: url("https://i.imgur.com/BTlDf4K.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 120px;
}

.contact .heading {
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 100px;
  /* or 125% */

  text-transform: uppercase;

  color: #000937;
}

.contact .subheading {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  /* or 187% */

  color: rgba(0, 9, 55, 0.5);
}

.contact .socials-icons {
  width: 16.6px;
  height: 16.6px;

  color: #000937;
  margin-right: 45px;
}

.socials-box {
  margin-top: 64px;
  margin-bottom: 133px;
}

.contact .right-col {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 120px;
}

.contact form div {
  margin-bottom: 24px;
}

.contact form label {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  /* identical to box height, or 200% */

  text-transform: uppercase;
}

.contact form select,
.contact form input,
.contact form textarea {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  /* identical to box height, or 200% */

  /* Text 1 */

  color: #293856;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #00d1ff;
  box-sizing: border-box;
  border-radius: 5px;
}

.contact .btn {
  margin-bottom: 24px;
}

.error404 .heading {
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 100px;
  /* or 125% */

  text-transform: uppercase;

  color: #000937;

  margin-bottom: 16px;
}

.error404 .subheading {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;

  color: #000937;
  margin-bottom: 70px;
}

.error404 .left-col {
  padding-top: 138px;
  padding-bottom: 138px;
  padding-left: 108px;
}

.error404 .erorr-image {
  max-width: 100%;
}

@media only screen and (max-width: 1400px) {

  .meet-button{
    margin-left: 0px!important;
    display: block;
    margin-top: 20px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {

  .home .header .left-col {
    text-align: center;
    padding-left: 0px;
    margin-top: 80px;
  }

  .contact .left-col,
  .error404 .left-col,
  .contact .right-col {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 55px;
  }

  .home .header .right-col {
    /* display: none; */
    padding-top: 80px;
  }

  .home .header .heading-illustration {
    /* display: none; */
    position: static;
    width: 399px;
    height: 438.9px;
    margin-left: 10px;
  }

  .home .header .alu-logo-image {
    display: none;
  }

  .home .header .left-col {
    padding-left: 0px;
  }

  .navbar-nav {
    margin-left: 24px !important;
    margin-top: 30px;
  }

  .nav-hr {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 32px;
    margin-bottom: 42px;
  }

  .nav-mobile-socials-buttons {
    margin-left: 24px !important;
    margin-right: 24px !important;
    text-align: center;
  }

  .nav-marketplace-button-mobile {
    margin-top: 40px;
    margin-right: 24px;
    margin-left: 24px;
    width: calc(100% - 40px) !important;
  }

  .heading-container {
    padding-left: 56px;
    padding-right: 0px;
  }

  .explore-block {
    display: none;
  }

  .smart-nft-img {
    width: 299px;
    height: 341px;
  }

  .partners-row-upper,
  .partners-row-lower {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {

  .home .header .title {
    text-align: center;
  }

  .home .header .tagline {
    text-align: center;
  }

  .home .header .left-col {
    text-align: center;
    padding-left: 0px;
    margin-top: 80px;
  }

  .home .header .title {
    text-align: center;
  }

  .home .header .tagline {
    text-align: center;
  }

  .home .header .left-col {
    text-align: center;
    padding-left: 0px;
    margin-top: 80px;
  }

  .home .header .right-col {
    margin-left: -30px;
    max-height: 547px;
    padding-top: 45px;
    margin-top: 53px;
    overflow-x: visible;
  }

  .home .header .heading-illustration {
    /* display: none; */
    position: static;
    width: 399px;
    height: 438.9px;
  }

  .heading-container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .home .header .explore-text {
    text-align: center;
    margin-top: 82px;
  }

  .home .header .explore-image-col {
    text-align: center;
    margin-right: 0px;
  }

  .team-title-block {
    height: 378px;
  }

  .news .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .footer .footer-frosted {
    padding-left: 16px;
    padding-right: 60px;
  }

  .roadmap .roadmap-section {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .color-circle-image-2{
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {

  .home .header .title {
    font-size: 60px;
    line-height: 75px;
    text-align: center;
  }

  .home .header .tagline {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }

  .home .header .left-col {
    text-align: center;
    padding-left: 0px;
    margin-top: 80px;
  }

  .home .header .heading-illustration {
    /* display: none; */
    position: static;
    width: 350px;
    height: 385px;
  }

  .home .header .right-col {
    padding-top: 60px;
  }

  .roadmap .roadmap-section .list-right {
    margin-top: 0px;
  }

  .roadmap .roadmap-section {
    padding-left: 10px !important;
    margin-bottom: 25px !important;
    margin-left: 24px !important;
    margin-right: 24px !important;
    padding-bottom: 60px;
  }

  .roadmap-image {
    margin: 0 !important;
    position: static !important;
  }

  .roadmap-image-div {
    text-align: left;
    margin-bottom: 0 !important;
    margin-top: 46px;
  }

  .roadmap .list,
  .roadmap .list-right {
    padding-left: 24px;
  }

  .roadmap .roadmap-image-div {
    height: 100%;
  }

  .footer .links-row {
    margin-top: 24px;
  }

  .footer-links-heading,
  .footer-link {
    text-align: left !important;
  }

  .footer .col-4 {
    padding-left: 0px;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {

  .home .header .title {
    font-size: 50px;
    line-height: 65px;
    text-align: center;
  }

  .home .header .tagline {
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }

  .home .header .left-col {
    text-align: center;
    padding-left: 0px;
    margin-top: 80px;
  }

  .explore-btn {
    width: 100% !important;
  }

  .home .header .buy-alu-btn {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .home .header .buy-alu-semi-circle {
    display: none;
  }

  .footer .footer-frosted {
    padding-left: 4px;
    padding-right: 4px;
  }

  .tagline-wrap{
    display: none;
  }

  .footer-frosted .container{
    padding: 2px;
  }

}

.banner {
  height: 60px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  background-color: #F65AE2 !important;
  display: table;
  overflow: hidden;
}

.banner a, .banner a:hover {
  text-decoration: none;
}

.alu-logo-image {
  top: 120px !important;
}

.heading-illustration {
  top: 190px !important;
}

.banner-title {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 37px;
  color: white;
}

.banner-title-div {
  display: table-cell;
  vertical-align: middle;
}

.home-news-body, .article-body .body{ overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3; /* number of lines to show */ line-clamp: 3; -webkit-box-orient: vertical; }

.home .header.container{
  padding-left: 0;
  padding-right: 0;
}
